import "../hosting_con/hosting_con.scss";
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
    <footer className="bg-dark text-white py-4">
        <Container>
            <Row>
                <Col md={6}>
                    <h5>HostingPro</h5>
                    <p>Cung cấp dịch vụ hosting hàng đầu với hiệu suất cao và bảo mật mạnh mẽ.</p>
                </Col>
                <Col md={6} className="text-right">
                    <p>&copy; 2024 HostingPro | <a href="/privacy" className="text-white">Chính sách bảo mật</a></p>
                </Col>
            </Row>
        </Container>
    </footer>
);

export default Footer;
