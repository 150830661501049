import { Container, Row, Col, Button } from 'react-bootstrap';
import "../hosting_con/hosting_con.scss";
const Deals = () => (
    <Container className="my-5">
        <h2 className="text-center mb-4">Ưu Đãi Hosting Đặc Biệt</h2>
        <Row>
            <Col md={4}>
                <div className="deal-card p-3 bg-dark text-white">
                    <h3>85% Giảm Giá</h3>
                    <p>Shared & WordPress Hosting bắt đầu từ $1.49/tháng</p>
                    <Button variant="outline-light" href="https://www.hosting-deal.com/">Xem Deal</Button>
                </div>
            </Col>
            <Col md={4}>
                <div className="deal-card p-3 bg-dark text-white">
                    <h3>50% Giảm Giá</h3>
                    <p>VPS Hosting bắt đầu từ $29.99/tháng</p>
                    <Button variant="outline-light" href="https://www.hosting-deal.com/">Xem Deal</Button>
                </div>
            </Col>
            <Col md={4}>
                <div className="deal-card p-3 bg-dark text-white">
                    <h3>30% Giảm Giá</h3>
                    <p>Reseller Hosting bắt đầu từ $19.6/tháng</p>
                    <Button variant="outline-light" href="https://www.hosting-deal.com/">Xem Deal</Button>
                </div>
            </Col>
        </Row>
    </Container>
);

export default Deals;
