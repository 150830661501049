import Deals from "./Deals";
import Footer from "./Footer";
import Header from "./header_hosting";
import HeroSection from "./HeroSection";
import HostingServices from "./HostingServices";
import '../hosting_con/hosting_con.scss'
const MainHosting = () => {
    return (
        <>
            <div>
                <Header />
                <HeroSection />
                <HostingServices />
                <Deals />
                <Footer />
            </div>
        </>
    )
};
export default MainHosting