import { Button, Container, Row, Col } from 'react-bootstrap';

const HeroSection = () => (
    <div className="hero-section text-white text-center bg-primary py-5">
        <Container>
            <Row>
                <Col>
                    <h1 className="display-4">Chọn Dịch Vụ Hosting Tốt Nhất 2024</h1>
                    <p className="lead">
                        Tìm hiểu các dịch vụ hosting tốt nhất để tối ưu hóa website của bạn!
                    </p>
                    <Button variant="light" size="lg">
                        Xem Dịch Vụ
                    </Button>
                </Col>
            </Row>
        </Container>
    </div>
);

export default HeroSection;
