import { Card, Button, Row, Col, Container } from 'react-bootstrap';

const HostingServices = () => (
    <Container className="my-5">
        <h2 className="text-center mb-4">Dịch Vụ Hosting Nổi Bật</h2>
        <Row>
            <Col md={4}>
                <Card>
                    <Card.Img variant="top" src="https://via.placeholder.com/300" />
                    <Card.Body>
                        <Card.Title>A2 Hosting</Card.Title>
                        <Card.Text>
                            Hosting với tốc độ vượt trội và bảo mật cao, phù hợp cho các website
                            có lượng truy cập lớn.
                        </Card.Text>
                        <Button variant="primary" href="https://www.a2hosting.com/">Xem thêm</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <Card.Img variant="top" src="https://via.placeholder.com/300" />
                    <Card.Body>
                        <Card.Title>Hostinger</Card.Title>
                        <Card.Text>
                            Hosting giá rẻ, dễ dàng sử dụng với nhiều tính năng mạnh mẽ, phù hợp cho
                            các cá nhân và doanh nghiệp nhỏ.
                        </Card.Text>
                        <Button variant="primary" href="https://www.hostinger.com/">Xem thêm</Button>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card>
                    <Card.Img variant="top" src="https://via.placeholder.com/300" />
                    <Card.Body>
                        <Card.Title>Bluehost</Card.Title>
                        <Card.Text>
                            Dịch vụ hosting được WordPress khuyến nghị, phù hợp cho những người mới bắt đầu.
                        </Card.Text>
                        <Button variant="primary" href="https://www.bluehost.com/">Xem thêm</Button>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);

export default HostingServices;
